import React, { useEffect } from "react";
import "./App.css";
import { log } from "./log";
import { SetTracePageId } from "@ctrip/corp-cross-utils";
function App() {
  const onclick = (keyName: string) => {
    return () => {
      log(keyName, {}, { isTrace: true });
      window.location.href = "https://ct.ctrip.com/login?group=huasheng";

    };
  };

  useEffect(() => {
    SetTracePageId("10650106626"); 
  }, []); 

  return (
    <>
      <div className="container">
        <header>
          <div style={{ display: "flex" }}>
            <img src={require("./assets/logo.png")} alt="" />
            <button
              className="button2"
              onClick={onclick("c_corp_huasheng_onlinehome_login_click")}
            >
              登录
            </button>
          </div>
        </header>
        <div className="context">
          <p
            style={{
              position: "absolute",
              margin: 0,
              top: "178px",
              width: "1174.27px",
              height: "79px",
              color: "#ffffff",
              fontSize: "66px",
              fontFamily: "CTRIPTYPE-BOLD",
              left: "50%",
              transform: "translateX(-50%)",
            }}
          >
            智慧差旅 伴您出行
          </p>
          <div
            style={{
              width: 1174.27,
              position: "absolute",
              left: "50%",
              transform: "translateX(-50%)",
              top: 288,
            }}
          >
            <button
              className="button1"
              onClick={onclick("c_corp_huasheng_onlinehome_experience_click")}
            >
              立即体验
              <div></div>
            </button>
          </div>
        </div>
        <div className="context1">
          <div className="introduction">
            <p className="title">公司简介</p>
            <p
              style={{
                width: "38px",
                height: "9px",
                backgroundColor: "#e62035",
                marginLeft: "58px",
                marginTop: "14px",
              }}
            ></p>
            <p
              style={{
                textAlign: "justify",
                width: "1059px",
                height: "74px",
                color: "#0c1f52",
                fontSize: "16px",
                fontFamily: "HarmonyOS_Sans_SC",
                marginLeft: "58px",
                marginTop: "21px",
              }}
            >
              西安华圣商旅服务有限公司成立于2004年，专注于为政府、企业、高校等提供机票、酒店、火车票、用车、MICE会议等差旅服务。现与携程商旅达成深度合作，凭借其强大的资源能力和先进的技术能力，华圣商旅协助企业降本增效，为企业节约高达30％的差旅费用，致力于成为国内领先的差旅管理公司。
            </p>
          </div>
        </div>
        <footer>
          Copyright© 2023, huashengtrip.com. All rights reserved. ICP证：
          <a href="http://beian.miit.gov.cn/">陕ICP备2023011281号</a>
          <div style={{margin:'0 auto',width:'200px'}}><img src={require("./assets/footer.png")} alt="" style={{width:'200px',height:'40px',marginTop:'0px'}}/></div>
        </footer>
      </div>
    </>
  );
}

export default App;
